<template>
  <div id="screenSize" class="mt-down-header mb-30">
    <!-- breadcrumb -->
    <base-breadcrumb :crumbs="items" class="c-m-r c-m-l" />
    <!-- breadcrumb end -->

    <!-- has data account -->
    <div v-if="hasData">
      <!-- strategy info -->
      <div class="strategy-info c-border c-m-r c-m-l">
        <div class style="width:70%;float:left">
          <h1>当前策略：
            <router-link
              :to="&quot;/strategy-detail/&quot; + accountData.class_id + &quot;/&quot; +accountData.strategy_id"
            >{{ accountData.strategy_name }}</router-link>
          </h1>
          <div class="flex-common mt-30" style="flex-wrap: wrap">
            <p>
              <span>开始日期：</span>
              {{ accountData.create_as_of_date }}
            </p>
            <!-- <p>
            <span>实盘天数：</span>
            {{accountData.measure_days}}
          </p>-->
            <p>
              <span>调仓时点：</span>
              {{ accountData.adjusting_the_position }}
            </p>
            <p>
              <span>调仓周期：</span>
              {{ accountData.adjustment_cycle }}
            </p>
            <!-- <p>
            <span>上次调仓</span>
            {{accountData.last_day}}
          </p>-->
            <p>
              <span>下次调仓：</span>
              {{ accountData.next_day }}
            </p>
          </div>
        </div>
        <div class="info-right" style="margin-left:80%">
          <router-link to="/leaderboard">
            <button class="redbtn">选择策略</button>
          </router-link>

          <!-- <button
            class="mt-30 stopbtn"
            :class="{green: trading}"
            @click="toggleBtn()"
          >{{ trading ? '启动状态' : '停止状态' }}</button> -->

          <el-switch
            :value="value"
            class="mt-30"
            style="display: block"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启动状态"
            inactive-text="停用状态"
            @click.native="changestate"
          />
        <!-- <p class="flex-common share-icon mt-30">
          分享：
          <img class="weixin" src="@/assets/img/weixin@2x.png" alt />
          <img class="weibo" src="@/assets/img/weibo@2x.png" alt />
        </p>-->
        </div>
      </div>
      <p style="color:#eb483f;font-size:15px; text-align:right; margin: 0 105px">注:第一次交易的用户在18点后开启，需下一个交易日才能生效交易！</p>
      <!-- strategy info end -->

      <!-- transaction show -->
      <div class="transaction-show c-border c-m-r c-m-l mt-30">
        <div class="index-border-bottom my-card-title">账户信息</div>
        <div class="strategy-list pb-0">
          <div v-if="accountData.Total_account !== ''" class="row m-lr">
            <div class="content-index col-3 p-lr">
              <div class="main-index flex-center-column">
                <p style="text-algin: center; color:grey; margin-bottom:0;">最新盈亏</p>
                <div
                  class="first"
                  :class="Number( accountData.Profit_and_loss) < 0 ? 'fall-green' : 'rise-red'"
                >{{ accountData.Profit_and_loss }}</div>
                <p
                  style="text-algin: center;font-size:18px; color:grey; margin-bottom:0; margin-top: 20px;"
                >
                  总额
                  <span
                    class="second"
                    :class="Number( accountData.Total_account) < 0 ? 'fall-green' : 'rise-red'"
                  >{{ accountData.Total_account }}</span>
                </p>
              </div>
              <div class="index-border-bottom index-border-top mb-15">
                <div class="another-index flex-common mt-15 mb-15">
                  <div class="flex-center-column index-border-right">
                    <p
                      :class="Number( accountData.Total_return) < 0 ? 'fall-green' : 'rise-red'"
                    >{{ accountData.Total_return }}</p>
                    <span>累积收益</span>
                  </div>
                  <div v-if="accountData.Year_return" class="flex-center-column">
                    <p
                      :class="Number( accountData.Year_return.replace('%', '')) < 0 ? 'fall-green' : 'rise-red'"
                    >{{ accountData.Year_return }}</p>
                    <span>年化收益</span>
                  </div>
                </div>
              </div>
              <div style="text-align:center;color:#b50101b0">*注意：数据截止于上一个交易日(19时)</div>
            <!-- <div>*注意：数据今天19</div> -->
            </div>
            <div class="col-9 p-lr chart-area">
              <Chart :option="chartOption" class="chart" style="height:350px;" />
              <ul class="strategy-time flex-common">
                <p>策略收益图</p>
              </ul>
            </div>
          </div>
          <div v-if="accountData.Total_account === ''" class="flex-center-column c-m-r c-m-l" style="padding:50px 0; color:grey">
            暂时没有交易数据
          </div>
        </div>
      </div>
      <!-- transaction show end -->

      <!-- transaction record -->
      <div class="transcation-record c-border mt-30 c-m-l c-m-r">
        <h1 class="my-card-title index-border-bottom">策略持仓</h1>
        <b-tabs v-if="accountData.Total_account !== ''" class="mt-30 tab-card">
          <p class="m-lr-30 mb-0" style="color:grey;text-align:right;font-size:14px">注：非交易日没有数据</p>
          <b-tab title="历史持仓" @click="holdRecord">
            <div class="m-lr-30 mt-30 mb-30 c-border">
              <b-table :fields="holdFields" :items="holdRecordItems" hover>
                <!-- 当日盈亏比 -->
                <template
                  v-slot:cell(Today_Profit_and_Loss)="data"
                >{{ data.item.Today_Profit_and_Loss }} / {{ data.item.Today_profit }}</template>
                <!-- 累积盈亏比 -->
                <template
                  v-slot:cell(Total_Profit_and_Loss)="data"
                >{{ data.item.Total_Profit_and_Loss }} / {{ data.item.Total_Profit }}</template>
              </b-table>
            </div>
            <div class="date-picker">
              <span>选择日期：</span>
              <flat-pickr
                v-model="tdatess"
                name="date"
                :config="{
                  defaultDate: new Date().setDate(new Date().getDate() - 1),
                  disable: [function(date) {return (date.getDay() === 0 || date.getDay() === 6)}]
                }"
                @on-change="holdRecordByDate"
              />
            </div>
          </b-tab>
          <b-tab title="历史交易" @click="changeHoldRecord">
            <div class="m-lr-30 mt-30 mb-30 c-border">
              <b-table :fields="changeHoldFields" :items="changeHoldRecordItems" hover />
            </div>
            <div class="date-picker">
              <span>选择日期：</span>
              <flat-pickr
                v-model="tdates"
                name="date"
                :config="{
                  defaultDate: new Date().setDate(new Date().getDate() - 1),
                  disable: [function(date) {return (date.getDay() === 0 || date.getDay() === 6)}]
                }"
                @on-change="changeHoldRecordByDate"
              />
            </div>
          </b-tab>
        </b-tabs>
        <div v-if="accountData.Total_account === ''" class="flex-center-column c-m-r c-m-l" style="padding:50px 0; color:grey">
          您暂时没有交易数据
        </div>
      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="recordData.total_records"
        class="m-lr-30 mb-30"
        @change="qureyByPage"
      ></b-pagination>-->
      </div>
    <!-- transaction record end -->
    </div>
    <!-- has data account end -->

    <!-- no data account -->
    <div v-if="!hasData" class="strategy-info flex-center-column c-m-r c-m-l" style="margin-top:8rem; color:grey">
      您暂时没有交易数据，请选择策略开始交易！
      <img src="https://static.ainvestcn.com/icon/no-data.jpg" alt="no data" style="width:30%; margin-top: 30px;" srcset="">
      <button class="redbtn" @click="changeFirstStrage">选择策略</button>
    </div>
    <!-- no data end account -->
  </div>

</template>

<script>
// import '../../assets/style/common.scss';
import Chart from '../../components/Chart'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { mapState } from 'vuex'
// import { getUserInfofromNewServer } from '../../api/api'

export default {
  components: {
    Chart,
    flatPickr
  },

  data() {
    return {
      // 日期控件
      tdate: new Date(),
      tdates: new Date(),
      tdatess: new Date(),
      // 面包屑导航
      isHome: false,
      // username: localStorage.getItem('username'),
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '个人主页',
          route: ''
        }
      ],

      // 策略数据
      accountData: {},

      // 图表
      chartOption: {},

      // 历史持仓
      holdFields: [
        { key: 'As_Of_Date', label: '日期' },
        { key: 'SecShortName', label: '股票名称' },
        { key: 'Industry', label: '所属行业' },
        { key: 'Cost_Price', label: '买入价格' },
        { key: 'Close_Value', label: '最近收盘价' },
        { key: 'Today_Profit_and_Loss', label: '当日盈亏(百分比/金额)' },
        { key: 'Total_Profit_and_Loss', label: '累积盈亏(百分比/金额)' }
      ],

      // uinfo2: {
      //   regTime: ''
      // },

      holdRecordItems: [],

      // 历史调仓
      changeHoldFields: [
        { key: 'As_Of_Date', label: '日期' },
        { key: 'SecShortName', label: '股票' },
        { key: 'Industry', label: '行业' },
        { key: 'Cost_Amount', label: '持股量' },
        { key: 'Cost_Price', label: '买入价' },
        { key: 'Close_Value', label: '卖出价' },
        { key: 'Today_profit', label: '当日盈亏' },
        { key: 'Total_Profit', label: '累积盈亏' }
      ],

      changeHoldRecordItems: [],
      // strategy time
      options: [
        { value: '4', text: '1个月' },
        { value: '3', text: '3个月' },
        { value: '2', text: '6个月' },
        { value: '1', text: '1年' },
        { value: '5', text: '3年' },
        { value: '9', text: '全部' }
      ],

      strategyTime: 1,

      // 日期控件
      date: null,
      trading: true,
      value: false,
      // exptime: '',

      // 是否有数据
      hasData: false
    }
  },

  computed: {
    ...mapState({
      brokerInfoData: state => state.login.brokerInfoData
    })
  },

  beforeMount() {
    this.fetchaccountData()
  },

  mounted() {
    // this.getContainerHeight();
    window.scrollTo(0, 1)
    this.holdRecord()
    // this.init()
    // this.fetchState()
    console.log('in user-acc', this.brokerInfoData)
  },

  methods: {
    // 获取用户的试用剩余时间
    // async init() {
    // const uinfo2 = await getUserInfofromNewServer(this.username)
    // const now = new Date()
    // const regtime = new Date(uinfo2.data.regTime)
    // const exptime = (now - regtime - 5 * 24 * 3600000) / 24 / 3600000
    // this.exptime = parseInt(exptime)
    // console.log(this.exptime)
    // localStorage.setItem('exptime', this.exptime)
    // },
    changeFirstStrage: function() {
      if (this.brokerInfoData.securities) {
        console.log('this.brokerInfoData.securities', this.brokerInfoData.securities)
        this.$router.push('/leaderboard')
      } else {
        if (confirm('您需要先确保有供交易的券商账户！')) {
          this.$router.push('/broker')
        }
      }
    },
    changestate() {
      if (this.value) {
        const url = 'api/office_web/change_use/'
        this.http.post(url, { status_code: 0 }).then(res => {
          console.log(this.value)
          this.value = false
          console.log(this.value)
          alert('已停用该策略')
        })
      } else {
        const url = 'api/office_web/change_use/'
        this.http.post(url, { status_code: 1 }).then(res => {
          console.log(this.value)
          this.value = true
          console.log(this.value)
          alert('已开启该策略')
        })
      }
    },

    // toggleBtn: function() {
    //   if (this.trading) {
    //     if (confirm('将停用策略自动交易，您确定要停止吗？')) {
    //       // const username = sessionStorage.getItem('session_username')
    //       // const url = 'v2/stop/abc/' + this.accountData.strategy_id + '/' + username
    //       const url = 'api/office_web/change_use/'
    //       this.http.post(url, { status_code: 0 }).then(
    //         (res) => {
    //           this.trading = !this.trading
    //           alert('已停用策略交易！')
    //         }
    //       )
    //     }
    //   } else {
    //     if (confirm('您确定要开启策略自动交易吗？')) {
    //       const url = 'api/office_web/change_use/'
    //       this.http.post(url, { status_code: 1 }).then(
    //         (res) => {
    //           this.trading = !this.trading
    //           alert('已启用策略交易！')
    //         }
    //       )
    //     }
    //   }
    // },

    // 获取策略数据
    fetchaccountData: function() {
      // this.strategyId = this.$route.params.strategyId;
      // this.categoryId = this.$route.params.categoryId;
      const apiurl = 'api/office_web/get_personal_info/'
      // 暂时写死的策略二
      this.http.get(apiurl).then(res => {
        this.accountData = res.data
        if (res.data.strategy_id !== null) {
          this.hasData = true
        }
        sessionStorage.setItem('session_currentStrategy_id', res.data.strategy_id)
        if (this.accountData.status_of_use === 1) {
          this.value = true
          // this.trading = true
        }
        if (this.accountData.status_of_use === 0) {
          // this.trading = false
          this.value = false
        }
        this.setChartOpition(res.data.data)
      })
      //  路由取参数
      // 页面挂载后获取数据和制图 制图
    },

    // 按时间查策略chart数据
    qureyByTime: function(value) {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId

      const apiurl =
        'api/office_web/strategy_chart/?strategy_number=' +
        this.strategyId +
        '&show_days=' +
        value +
        '&category_group=' +
        this.categoryId

      this.http.get(apiurl).then(res => {
        this.setChartOpition(res.data.data)
      })
      this.strategyTime = value
    },

    // 解析chart数据
    setChartOpition: function(data) {
      this.chartOption = {
        // 指定图表的配置项和数据

        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['策略', '同期最佳'],
          right: 80
        },
        dataZoom: [
          {
            startValue: ''
          },
          {
            type: 'inside'
          }
        ],
        xAxis: {
          data: data.map(function(item) {
            return item.As_Of_Date
          }),
          splitLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            name: '同期最佳',
            type: 'line',
            itemStyle: {
              color: '#1D2088FF'
            },
            data: data.map(function(item) {
              return item.returns
            }),
            areaStyle: {}
          },
          {
            name: '策略',
            type: 'line',
            itemStyle: {
              color: '#FF3600FF'
            },
            data: data.map(function(item) {
              return item.Response_rate
            }),
            areaStyle: {}
          }
        ]
      }
      // console.log('chartOption:',this.chartOption);
      // console.log('chartOption[i]:',this.chartOption[i]);
    },

    // 历史持仓table
    holdRecord: function() {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `api/office_web/personal_transaction_record/`
      this.http.get(recordApi).then(res => {
        this.recordData = res.data
        this.holdRecordItems = res.data.transaction_records
      })
    },

    // 按日期查历史持仓记录
    holdRecordByDate: function(date, value) {
      this.$nextTick(function() {
        this.strategyId = this.$route.params.strategyId
        this.categoryId = this.$route.params.categoryId
        const recordApi = `api/office_web/personal_transaction_record/?date=${value}`
        this.http.get(recordApi).then(res => {
          this.recordData = res.data
          this.holdRecordItems = res.data.transaction_records
        })
      })
    },

    // 历史交易table
    changeHoldRecord: function() {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `api/office_web/new_personal_adjust_record/`
      this.http.get(recordApi).then(res => {
        this.recordData = res.data
        this.changeHoldRecordItems = res.data.adjust_records
      })
    },

    // 按日期查历史交易记录
    changeHoldRecordByDate: function(date, value) {
      this.strategyId = this.$route.params.strategyId
      this.categoryId = this.$route.params.categoryId
      const recordApi = `api/office_web/new_personal_adjust_record/?date=${value}`
      this.http.get(recordApi).then(res => {
        this.recordData = res.data
        this.changeHoldRecordItems = res.data.adjust_records
      })
    },
    // 分页查询
    qureyByPage: function() {
      const recordApi = ``
      this.http.get(recordApi).then(res => {
        // this.recordData = res.data;
        this.recordItems = res.data.transaction_records
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-30 {
  margin-top: 30px;
}
.el-switch{
  width: 210px;
}
//strategy-info
.strategy-info {
  padding: 20px 34px;
  overflow: hidden;

  h1 {
    font-size: 30px;
    font-family: PingFang-SC-Bold;
    // font-weight:bold;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    font-family: FZLTXHK;
    font-weight: 400;
    letter-spacing: 2px;
    color: rgba(102, 102, 102, 1);
    margin-right: 20px;
    min-width: 210px;

    span {
      color: rgb(161, 161, 161);
    }
  }

  .green {
    background-color: green !important;
    color: #fff !important;
  }
  // .green:hover {
  //   background-color: #fff;
  //   color: green;
  // }

  .strategy-price {
    font-size: 30px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: rgba(255, 51, 51, 1);
    margin-right: 10px;
  }

  .share-icon {
    img {
      margin: 0 20px;
    }
    img.weixin {
      width: 34px;
      height: 27px;
    }

    img.weibo {
      width: 40px;
      height: 30px;
    }
  }

  button {
    width: 120px;
    height: 40px;
    border: 0;

    font-size: 16px;
    font-family: PingFang-SC-Medium;
  }
  .redbtn {
    background: rgba(255, 51, 51, 1);
    color: #fff;
    margin-left: 42px;
    font-size: 16px;
  }

  .redbtn:hover {
    border: 1px solid #ff3333ff;
    background-color: #fff;
    color: #ff3333ff;
  }

  .stopbtn{
    background-color:#666;
    color: #fff;
  }

  // .stopbtn:hover{
  //   border: 1px solid #666;
  //   background:#fff;
  //   color: #666;
  // }
}

.my-card-title {
  height: 87px;
  line-height: 87px;
  font-size: 26px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  padding-left: 30px;
  margin: 0;
}

// transaction show

.transaction-show {
  .strategy-list .chart {
    margin-top: 15px;
    margin-left: 0;
  }
}

.chart-area {
  position: relative;
}

//strategy time
.strategy-time {
  position: absolute;
  left: 0;
  top: 10px;
  margin-left: 20px;

  p {
    font-size: 20px;
    font-family: PingFang-SC-Medium;
    // font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 0;
    margin-right: 30px;
  }

  // div.first{
  //   color: ;
  // }

  li {
    list-style: none;
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
    padding: 5px 12px;
    margin-right: 30px;
    color: #999999ff;
    cursor: pointer;
  }

  li.active {
    background: rgba(255, 223, 223, 1);
    color: #ec3b3bff;
  }
}

.tab-card {
  position: relative;

  .date-picker {
    position: absolute;
    top: 0px;
    right: 30px;
  }
}

.first {
  font-size: 36px;
}

.second {
  font-size: 20px !important;
}
// .info-right {
//   display: flex;
//   flex-direction: column;
//   align-items: right;
// }
 .el-switch_label{
  font-size: 16px;
}
</style>
<style>
.el-switch__core{
  width: 58px !important;
  height: 25px;
}
.el-switch__label * {
  font-size: 16px !important;
}
.el-switch__core:after{
  top:3px;
}
</style>
